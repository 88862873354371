<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
/**
 * Starter page component
 */
export default {
  page: {
    title: "Normal user details",
    meta: [{ name: "description" }],
  },
  components: { Layout,PageHeader },
  data() {
    return {
        items: [
        {
          text: "Users",
          href: "/users/normal"
        },
        {
          text: this.$route.name,
          active: true
        }
      ],
    };
  },
  created() {
    if (!this.selectedUser) {
      this.$router.push({ name: "Normal Users" });
    }
  },
  computed: {
    selectedUser() {
      return this.$store.state.users.seleted;
    },
  },
  watch:{
    $route (to){
       this.items[1].text =  to.name;
    }
} 
};
</script>

<template>
  <Layout>
      <PageHeader :items="items" />
    <b-row>
      <b-col md="4">
        <b-card
          :img-src="
            selectedUser.cover_image ||
            require('@/assets/images/no-image-hr.png')
          "
          img-alt="Cover image"
          img-top
          tag="article"
          class="mb-2"
        >
          <b-card-body style="position: relative">
            <div class="user-profile-image">
              <b-avatar
                rounded
                :src="selectedUser.profile_image"
                size="4rem"
              ></b-avatar>
            </div>

            <div style="padding-left: 14px">
              <div
                style="font-size: 18px; font-weight: 600; margin-bottom: 8px"
              >
                {{ selectedUser.fullname }}
              </div>

              <div class="mb-1">
                <i class="far fa-envelope pr-2"></i>
                <strong>{{ selectedUser.email || "Not provided" }}</strong>
              </div>
              <div class="mb-1">
                <i class="fas fa-phone pr-2"></i>
                <strong>{{ selectedUser.phone_no || "Not provided" }} </strong>
              </div>
              <div class="mb-3 mt-2">
                <div class="row m-1">
                  <div class="col-6">
                    Active
                    <i
                      class="fas fa-check-circle"
                      v-if="!selectedUser.deleted"
                      style="color: green"
                    ></i>
                    <i
                      v-else
                      class="fas fa-times-circle"
                      style="color: red"
                    ></i>
                  </div>
                  <div class="col-6">
                    Public
                    <i
                      class="fas fa-check-circle"
                      style="color: green"
                      v-if="selectedUser.public"
                    ></i>
                    <i
                      style="color: red"
                      v-else
                      class="fas fa-times-circle"
                    ></i>
                  </div>
                </div>
              </div>
            </div>

            <b-list-group>
              <b-list-group-item 
                :active="$route.name === 'Normal User Overview'"
              >
                <router-link to="overview">Overview</router-link>
              </b-list-group-item>
              <b-list-group-item 
                :active="$route.name === 'Normal User Followers'"
                ><router-link to="followers"
                  >Followers <span class="user-details-badge float-right">{{selectedUser.followers && selectedUser.followers.length}}</span> </router-link
                ></b-list-group-item
              >
              <b-list-group-item
                :active="$route.name === 'Normal User Following'"
                ><router-link to="following"
                  >Following<span class="user-details-badge float-right">{{selectedUser.following && selectedUser.following.length}}</span> </router-link
                ></b-list-group-item
              >
              <b-list-group-item :active="$route.name === 'Normal User Posts'"
                ><router-link to="posts">Posts</router-link></b-list-group-item
              >
              <b-list-group-item :active="$route.name === 'Normal User Blocked'"
                ><router-link to="blocked"
                  >Blocked</router-link
                ></b-list-group-item
              >
         
              <b-list-group-item
                :active="$route.name === 'Normal User Settings'"
                ><router-link to="settings"
                  >Settings</router-link
                ></b-list-group-item
              >
            </b-list-group>
            <div class="mt-3 mb-4 border p-3" style="background-color: #fafafa">
              <div>
                <strong>Nomadic ID:</strong> {{ selectedUser.nomadic_id }}
              </div>
              <div>
                <strong>Role:</strong> {{ selectedUser.role }}
              </div>
              <div>
                <strong>Created:</strong>
                {{ moment(selectedUser.createdAt).format("LLL") }}
              </div>
              <div>
                <strong>Modified:</strong>
                {{ moment(selectedUser.updatedAt).format("LLL") }}
              </div>
              <div style="font-size: 12px">
                <strong>ID:</strong> {{ selectedUser._id }}
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="8">
        <transition name="fade">
          <router-view />
        </transition>
      </b-col>
    </b-row>
  </Layout>
</template>
<style scoped>
.user-profile-image {
  position: absolute;
  top: -62px;
  right: 0px;
  background-color: #fff;
  padding: 0px;
  border-radius: 8px;
}
.card-body {
  padding: 0.2rem;
}
.list-group-item {
  padding: 0rem;
  border: none;
}
.list-group-item:hover {
  cursor: pointer;
}
a {
  color: #000;
  display: block;
  padding:0.4rem;
}
.list-group .active a {
  color: #fff;
}
.user-details-badge{
  background-color:#f1f1f1;
  color:black;
  padding:0px 12px;
  border-radius:12px;
  margin-left:18px;
}
</style>